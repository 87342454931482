
import dialogShell from '@/components/common/dialog-shell/index.vue';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import {
    defineComponent, ref, Ref, watch
} from 'vue';
import { community } from '@/data';
import {
    listPagination,
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import { limitErrorImg } from '@/methods/basicFun';
import { getProjectData } from './util';

export default defineComponent({
    components: {
        dialogShell,
        listPagination,
        EnlargeImage
    },
    props: {
        tempKey: {
            type: String,
            required: true
        },
        tempKeyId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        // 办公没有apt相关
        watch(community.ProjectType, () => {
            const projectData = getProjectData()[community.ProjectType.value];
            headers.value = projectData.header;
        }, {
            immediate: true
        });
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'v4/web/office/log/pm/getDoorLog',
            param: {
                tempKeyValue: props.tempKey,
                tempKeyId: props.tempKeyId
            }
        });

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const showEnlargeImage = (address: string) => {
            picAddress.value = address;
            isShowEnlargeImage.value = true;
        };
        const mapWordOfAction = {
            call: WordList.ProperAllTextCall,
            tempKeyUnlock: WordList.ActionUnlock.format(WordList.ProperAllTextTempKey),
            localKeyUnlock: WordList.ActionUnlock.format(WordList.ProperAllTextPIN),
            rfCardUnlock: WordList.ActionUnlock.format(WordList.DeliveryRfCard),
            faceUnlock: WordList.ActionUnlock.format(WordList.ProperAllTextPhotos),
            remoteOpenDoor: WordList.ActionUnlock.format(WordList.ProperAllTextSmartplus),
            callUnlockApp: WordList.ActionUnlock.format(WordList.ProperAllTextSmartplus),
            callUnlockIndoor: WordList.ActionUnlock.format(WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor),
            callUnlockGuardPhone: WordList.ActionUnlock.format(WordList.SelectManagementPhone),
            pmUnlock: WordList.ActionUnlock.format(WordList.Manually),
            autoUnlock: WordList.ActionUnlock.format(WordList.Automatically),
            before: '--',
            nfcUnlock: WordList.ActionUnlock.format(WordList.NFC),
            bleUnlock: WordList.ActionUnlock.format(WordList.Bluetooth),
            handScreen: WordList.CaptureOnSmartPlus,
            callCapture: WordList.ProperAllTextCall,
            pmLock: WordList.ActionLock.format(WordList.Manually),
            autoLock: WordList.ActionLock.format(WordList.Auto),
            lockApp: WordList.ActionLock.format(WordList.ProperAllTextSmartplus),
            open: WordList.Open,
            close: WordList.TabelFootClose,
            deliveryUnlock: WordList.DeliverySystemVerification,
            booking: WordList.AmenityReservation,
            idAccess: WordList.IDAccess,
            handset: WordList.Handset
        };
        const mapWordOfDoorLogType = {
            call: WordList.ProperAllTextCall,
            doorRelease: WordList.ProperAllTextDoorRelease,
            doorClose: WordList.DoorClose,
            entry: WordList.Entry,
            exit: WordList.Exit,
            entryViolation: WordList.EntryViolation,
            exitViolation: WordList.ExitViolation
        };
        const mapWordOfResponse = {
            success: WordList.TabelChangePwStepSuccess,
            failed: WordList.PropertyAllTextFailed,
            offline: WordList.SelectOffline
        };
        return {
            updateToList,
            listRequestData,
            headers,
            picAddress,
            showEnlargeImage,
            isShowEnlargeImage,
            setAllImg,
            mapWordOfAction,
            mapWordOfDoorLogType,
            mapWordOfResponse
        };
    }
});

