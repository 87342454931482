import { ListHeaderItem } from '@/components/common/list';

type ProjectData = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
    }
}
const getProjectData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        name: 'CaptureTime',
        label: WordList.CaptureListTanleCaptureTime
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Location',
        label: WordList.ProperAllTextDoorPhone
    }, {
        name: 'Initiator',
        label: WordList.CaptureListTanleInitiator
    }, {
        name: 'RoomNum',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'LogType',
        label: WordList.LogInHtmlType
    }, {
        name: 'CaptureAction',
        label: WordList.CaptureListTanleAction
    }, {
        name: 'KeyNum',
        label: WordList.TmpKeyListTanleKey
    }, {
        name: 'Response',
        label: WordList.Response
    }, {
        name: 'SPicUrl',
        type: 'customize',
        label: WordList.capture
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        name: 'captureTime',
        label: WordList.CaptureListTanleCaptureTime
    }, {
        name: 'buildingName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'deviceName',
        label: WordList.ProperAllTextDoorPhone
    }, {
        type: 'customize',
        name: 'initiator',
        label: WordList.CaptureListTanleInitiator
    }, {
        type: 'customize',
        name: 'doorLogType',
        label: WordList.LogInHtmlType
    }, {
        type: 'customize',
        name: 'action',
        label: WordList.CaptureListTanleAction
    }, {
        name: 'key',
        label: WordList.TmpKeyListTanleKey
    }, {
        type: 'customize',
        name: 'response',
        label: WordList.Response
    }, {
        name: 'smallPicUrl',
        type: 'customize',
        label: WordList.capture
    }];
    const projectData = {
        community: {
            header: communityHeader
        },
        office: {
            header: officeHeader
        }
    };
    return projectData;
};

export default null;
export {
    getProjectData
};